export const COLOR_POPOVER_PROPS = {
	className: 'components-custom-gradient-picker__color-picker-popover',
	position: 'top',
};

export const GRADIENT_MARKERS_WIDTH = 16;
export const INSERT_POINT_WIDTH = 16;
export const MINIMUM_ABSOLUTE_LEFT_POSITION = 5;
export const MINIMUM_DISTANCE_BETWEEN_INSERTER_AND_POINT = 10;
export const MINIMUM_DISTANCE_BETWEEN_POINTS = 0;
export const MINIMUM_SIGNIFICANT_MOVE = 5;

export const KEYBOARD_CONTROL_POINT_VARIATION = MINIMUM_DISTANCE_BETWEEN_INSERTER_AND_POINT;
export const MINIMUM_DISTANCE_BETWEEN_INSERTER_AND_MARKER =
	( INSERT_POINT_WIDTH + GRADIENT_MARKERS_WIDTH ) / 2;
