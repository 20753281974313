/**
 * WordPress dependencies
 */
import { Path, SVG } from '@wordpress/primitives';

const mediaAndText = (
	<SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
		<Path d="M3 18h8V6H3v12zM14 7.5V9h7V7.5h-7zm0 5.3h7v-1.5h-7v1.5zm0 3.7h7V15h-7v1.5z" />
	</SVG>
);

export default mediaAndText;
